import styled, { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import '../common/assets/css/dubai.css';

export const GlobalStyle = createGlobalStyle`
  [lang="ar"] {
    direction: rtl;
  }

  section {
    position: relative;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  .error-message {
    color: #ff3333;
  }

  .input-error {
    input,textarea {
      border: 1px solid #ff3333 !important;
    }
  }
  
  .select-error {
    .css-yk16xz-control {
      border: 1px solid #ff3333 !important;
    }
  }

  .input {
    margin-bottom: 25px;
  }
  
  .drawer-content-wrapper{
    @media (max-width: 767px) {
      width: 300px!important;
    }
    .drawer-content {
      padding: 60px;     
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 767px) {
        padding: 50px 40px 30px 40px;
      }
      .mobile_menu {
        margin-bottom: 40px;
        flex-grow: 1;
        @media (max-width: 767px) {
          margin-bottom: 30px;
        }
        li{
          margin-bottom: 35px;
          @media (max-width: 767px) {
            margin-bottom: 25px;
          }
          a{
            font-size: 20px;
            font-weight: 400;
            color: #343d48;
            position: relative;
            transition: 0.15s ease-in-out;
            @media (max-width: 767px) {
              font-size: 18px;
            }
            &:hover {
              color: #fe7002;
            }
            &:before{
              content: '';
              width: 7px;
              height: 7px;
              background: #fe7002;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: -15px;
              transform: translateY(-50%);
              opacity: 0;
            }
          }
          &.is-current {
            a {
              color: #fe7002;
              &:before{
                opacity: 1;
              }
            }
          }
        }
      }
      .navbar_drawer_button button{
        width: 100%;
      }
    }

    .reusecore-drawer__close{
      width: 34px;
      height: 34px;
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fe7002;
      cursor: pointer;
      @media (max-width: 767px) {
        top: 15px;
        right: 15px;
      }
    }
  }

  /* Modal default style */
  button.modalCloseBtn {
    color: ${themeGet('colors.white', '#ffffff')} !important;

    &.alt {
      background-color: ${themeGet('colors.primary', '#5268db')} !important;
      box-shadow: 0 9px 20px -5px rgba(82, 104, 219, 0.57) !important;
    }   
  }
`;

export const ContentWrapper = styled.div`
    position: relative;
    overflow: hidden;
    a:-webkit-any-link {
        text-decoration: none;
    }
    .sticky-nav-active {
        .hosting_navbar {
            background: #fff;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
            padding: 15px 0;
        }
    }

    .background-shape__contact {
        @media (max-width: 1396px) {
            display: none;
        }
    }

    .background-shape__clients {
        @media (max-width: 1414px) {
            display: none;
        }
    }

    .partner-history__clients {
        margin-top: 150px;
    }

    .hosting_navbar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        transition: 0.35s ease-in-out;
        padding: 30px 0;

        .nav-row {
            display: flex;
            align-items: center;
            width: 100%;

            @media (max-width: 1396px) {
                justify-content: space-between;
            }
        }

        .main_menu {
            margin-right: 40px;
            li {
                display: inline-block;
                padding-left: 13px;
                padding-right: 13px;
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
                &.is-current {
                    a {
                        color: #fe7002;
                    }
                }
                a {
                    padding: 5px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #343d48;
                    transition: 0.15s ease-in-out;
                    &:hover {
                        color: #fe7002;
                    }
                }
            }
            @media (max-width: 1396px) {
                display: none;
            }
        }
        .buttonsWrapper {
            @media (max-width: 1396px) {
                display: none;
            }
        }
        .reusecore-drawer__handler {
            @media (min-width: 1398px) {
                display: none !important;
            }
        }
    }

    .trial-section {
        background: linear-gradient(to bottom, #fafbff 0%, #f7f8fd 100%);

        .button_group {
            text-align: center;
        }
    }

    @media (max-width: 1396px) {
        .glide__slide--active .pricing_table {
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
            border: 0;
        }
    }
`;
